import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Address,
  ContactDiv,
  ContactFormContainer,
  FooterAddress,
  FooterContainer,
  FooterLogo,
  FooterText,
  GetInTouch,
  MediaLinks,
} from "./Footer.styles";
import blackcircle from "../../Assets/Images/Logo/Black Circle.png";
import { Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";

function Footer() {
  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [submissionText, setSubmissionText] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email_address: formData.email,
      phone_number: formData.phone,
      message: formData.message,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "submit-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();

      if (response.ok) {
        setSubmissionStatus("success");
        setSubmissionText(responseData.message);
        setFormData(initialData);
      } else {
        setSubmissionStatus("error");
        setSubmissionText(responseData.message);
      }
    } catch (error) {
      setSubmissionStatus("error");
      setFormData("An Error Occured");
    }
  };

  return (
    <div>
      <FooterContainer id="Contact">
        <FooterAddress>
          <FooterLogo src={blackcircle} />
          <FooterText>
            <GetInTouch>GET IN TOUCH</GetInTouch>
            <br />
            <b style={{ color: "white", padding: " 10px 0px" }}>Offices:</b>
            <Address>
              <div
                style={{
                  borderRight: window.innerWidth > 768 && "2px solid white",
                  paddingRight: window.innerWidth > 768 && "20px",
                  borderBottom: window.innerWidth <= 768 && "2px solid white",
                  paddingBottom: window.innerWidth <= 768 && "20px",
                }}
              >
                Chamber Number 9B/1 <br />
                District Court Complex
                <br />
                Rupnagar 140001
              </div>
              <div>
                Chamber Number 41
                <br />
                District and Sessions Court <br />
                SCO 43, Chandigarh 160043
              </div>
            </Address>
            <br />
            <b style={{ color: "white", padding: " 10px 0px" }}>Contact Us:</b>
            <Address>
              <div>
                Advocate Sanyam Jain
                <br />
                B.Com LL.B (Hons)
                <br />
                Punjab and Haryana High Court
                <br />
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="tel:+917888835834"
                >
                  +91 78888-35834
                </a>
                <br />
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="mailto:sudharmalegalassociate@gmail.com"
                >
                  sudharmalegalassociate@gmail.com
                </a>
              </div>
            </Address>
            <br />
            <MediaLinks>
              <a
                href="https://x.com/sudharmalegal"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter sx={{ fontSize: "25px", color: "white" }} />
              </a>
              <a
                href="https://www.linkedin.com/company/sudharma-legal-associates/about/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn sx={{ fontSize: "25px", color: "white" }} />
              </a>
              <a
                href="https://www.instagram.com/sudharmalegal"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram sx={{ fontSize: "25px", color: "white" }} />
              </a>
            </MediaLinks>
          </FooterText>
        </FooterAddress>
        <ContactFormContainer id="footer">
          <ContactDiv>
            <Form onSubmit={handleSubmit} style={{ width: "90%" }}>
              {submissionStatus === "success" && (
                <Alert variant="success">{submissionText}</Alert>
              )}
              {submissionStatus === "error" && (
                <Alert variant="danger">{submissionText}</Alert>
              )}
              {window.innerWidth > 768 ? (
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label
                      style={{ fontFamily: "Raleway", fontWeight: "600" }}
                    >
                      First Name*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      required
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="Enter your first name"
                      style={{ borderRadius: "0px", border: "1px solid black" }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label
                      style={{ fontFamily: "Raleway", fontWeight: "600" }}
                    >
                      Last Name*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      required
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Enter your last name"
                      style={{ borderRadius: "0px", border: "1px solid black" }}
                    />
                  </Form.Group>
                </Row>
              ) : (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label
                        style={{ fontFamily: "Raleway", fontWeight: "600" }}
                      >
                        First Name*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        required
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="Enter your first name"
                        style={{
                          borderRadius: "0px",
                          border: "1px solid black",
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label
                        style={{ fontFamily: "Raleway", fontWeight: "600" }}
                      >
                        Last Name*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        required
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Enter your last name"
                        style={{
                          borderRadius: "0px",
                          border: "1px solid black",
                        }}
                      />
                    </Form.Group>
                  </Row>
                </>
              )}
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label
                    style={{ fontFamily: "Raleway", fontWeight: "600" }}
                  >
                    Email*
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Enter your email"
                    style={{ borderRadius: "0px", border: "1px solid black" }}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label
                    style={{ fontFamily: "Raleway", fontWeight: "600" }}
                  >
                    Phone Number*
                  </Form.Label>
                  <PhoneInput
                    country={"in"}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    inputStyle={{
                      width: "100%",
                      borderRadius: "0px",
                      border: "1px solid black",
                      background: "white",
                      height: "95%",
                    }}
                    containerStyle={{ height: "70%" }}
                    buttonStyle={{
                      height: "95%",
                      background: "white",
                      border: "1px solid black",
                    }}
                    dropdownStyle={{
                      background: "white",
                      border: "1px solid black",
                    }}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: false,
                    }}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label
                    style={{ fontFamily: "Raleway", fontWeight: "600" }}
                  >
                    Message
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Enter your message"
                    style={{ borderRadius: "0px", border: "1px solid black" }}
                  />
                </Form.Group>
              </Row>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "black",
                  border: "none",
                  borderRadius: "0px",
                  fontFamily: "Raleway",
                }}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </ContactDiv>
        </ContactFormContainer>
      </FooterContainer>
    </div>
  );
}

export default Footer;
