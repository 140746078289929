export const LegalServicesData = [
    {
        "Heading": "CIVIL SUITS",
        "Image": require("../Assets/Images/Images/lukas-blazek-mcSDtbWXUZU-unsplash.jpg"),
        "Sub": [
            "We offer an initial consultation to understand the details of your case.",
            "We deal with property-related issues, land disputes, money disputes, will disputes, and succession matters.",
            "We evaluate the merits of the case, identifying key issues and potential outcomes.",
            "We have filed and successfully won numerous civil suits through favorable judgments in Punjab District courts trials"
        ]
    },
    {
        "Heading": "CRIMINAL CASES",
        "Image": require("../Assets/Images/Images/gavel-3577254_1280.jpg"),
        "Sub": [
            "We provide clear advice on the legal process, potential outcomes, and strategic options.",
            "We explore all possible defenses, including alibis, self-defense, and constitutional violations.",
            "We prepare meticulously for pre-trial hearings and ensure our clients are well-informed about each step."
        ]
    },
    {
        "Heading": "MATRIMONIAL DISPUTES",
        "Image": require("../Assets/Images/Images/ben-rosett-WdJkXFQ4VHY-unsplash.jpg"),
        "Sub": [
            "We guide clients through every step, ensuring their rights and interests are protected in the Punjab and Haryana district courts.",
            "We advocate for fair custody and visitation arrangements that prioritize the child's well-being.",
            "We work diligently to enforce child support orders in the district courts that meet the child's needs.",
            "We assist in identifying, valuing, and fairly dividing marital assets and debts.",
            "We prepare and review agreements to protect clients' financial interests before and during marriages."
        ]
    },
    {
        "Heading": "CONSUMER COMPLAINTS",
        "Image": require("../Assets/Images/Images/cytonn-photography-GJao3ZTX9gU-unsplash.jpg"),
        "Sub": [
            "We specialize in navigating the complexities of consumer complaints, ensuring swift and satisfactory resolutions for our clients.",
            "From faulty products to unsatisfactory services, we handle a wide range of consumer disputes with expertise and efficiency.",
            "We leverage our legal expertise to maximize compensation and remedies available to consumers, ensuring they receive fair and just outcomes.",
            "We provide comprehensive consumer advocacy, addressing various issues with experience in Punjab and Haryana district courts."
        ]
    },
    {
        "Heading": "DEED AND AGREEMENTS",
        "Image": require("../Assets/Images/Images/pexels-shkrabaanthony-5816283.jpg"),
        "Sub": [
            "We specialize in guiding clients through the intricacies of deeds and agreements, ensuring clarity and accuracy every step of the way.",
            "Whether buying, selling, or leasing property, we facilitate seamless transactions by drafting and negotiating clear and comprehensive deeds and agreements.",
            "We ensure that all deeds and agreements comply with relevant indian laws and regulations, guaranteeing their validity and enforceability.",
            "We assist with agreements across India and provide specialized support for NRIs in Punjab and Haryana."
        ]
    },
    {
        "Heading": "CHEQUE BOUNCE",
        "Image": require("../Assets/Images/Images/pexels-rdne-7841838.jpg"),
        "Sub": [
            "We specialize in guiding clients through the complexities of cheque bounce cases, ensuring clarity and effective resolution at every stage.",
            "We pursue all available legal remedies to recover the owed amount, holding the issuer accountable for their financial obligations.",
            "From issuing legal notices to initiating legal proceedings, we guide our clients through the necessary legal procedures involved in pursuing a cheque bounce case."
        ]
    },
    {
        "Heading": "COURT MARRIAGE AND COURT PROTECTION",
        "Image": require("../Assets/Images/Images/pexels-tahaasamett-7781900.jpg"),
        "Sub": [
            "We specialize in facilitating court marriages with efficiency and accuracy, ensuring a seamless and hassle-free process for couples seeking to formalize their union.",
            "From obtaining marriage licenses to completing necessary paperwork, we guide couples through all the legal formalities involved in a court marriage, ensuring compliance with relevant laws and regulations.",
            "We provide court protection services to individuals facing threats, harassment, or violence, helping them obtain legal protection orders to safeguard their safety and well-being."
        ]
    },
    {
        "Heading": "LABOUR LAW AND RIGHTS",
        "Image":require("../Assets/Images/Images/pexels-thirdman-5060557.jpg"),
        "Sub": [
            "Fair treatment in the workplace, fighting against discrimination, exploitation, and unfair labour practices.",
            "From employment contracts to workplace safety regulations, we guide workers through the complexities of indian labour law, ensuring they understand their rights and obligations.",
            "We work tirelessly to secure just compensation for workers who have been wronged, whether through unpaid wages, wrongful termination, or other labour violations as per indian labour law.",
            "We hold employers accountable for complying with labour laws and regulations, advocating for workplace practices that promote fairness, equality, and respect for workers' rights."
        ]
    },
    {
        "Heading": "LEGAL NOTICES AND SERVICES",
        "Image": require("../Assets/Images/Images/kelly-sikkema--1_RZL8BGBM-unsplash.jpg"),
        "Sub": [
            "We specialize in navigating the intricacies of legal notices with precision and efficiency, ensuring that our clients’ interests are protected and their objectives are achieved.",
            "We excel in drafting clear and concise legal notices that effectively communicate our clients’ demands, requirements, or grievances to the intended recipients.",
            "Additionally, we assist with legal agreements across India, providing expert guidance and support to ensure effective and compliant transactions."
        ]
    }
];
