export const FocusData = [
    {
        "icon":require("../Assets/Images/trial.png"),
        "Heading": "Exceptional Professionalism",
        "Sub": "We brings a wealth of experience and a deep understanding of the law to every case we handle. We are committed to maintaining the highest standards of professionalism in all our interactions and ensures that our client receive the best possible legal advice and representation."
    },
    {
        "icon":require("../Assets/Images/people.png"),
        "Heading": "Dedication to Justice",
        "Sub": "Our core mission is to deliver justice for our clients. We believe in fighting vigorously for your rights and interests, no matter how complex or challenging your case may be. Your pursuit of justice is our top priority, and we are relentless in our efforts to achieve favorable outcomes."
    },
    {
        "icon":require("../Assets/Images/bank.png"),
        "Heading": "Client-Centered Approach",
        "Sub": "We understand that every client and every case is unique. That's why we take a personalized approach, carefully listening to your needs and crafting legal strategies tailored to your specific situation. Our goal is to ensure that you feel supported and informed throughout the entire legal process."
    },
    {
        "icon":require("../Assets/Images/balance.png"),
        "Heading": "Comprehensive Legal Services",
        "Sub": "Whether you’re facing civil litigation, criminal defense, or matrimonial disputes, we have the expertise with wide array of legal matters. Our comprehensive services offer all your legal needs, including representation in the Punjab and Haryana High Court as well as in the district courts across Punjab and Haryana."
    }
];
