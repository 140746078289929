import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Header from '../Components/Header/Header';
import Hero from '../Components/Hero/Hero';
import Hero2 from '../Components/Hero2/Hero2';
import { BannerContainer, BannerQuote, WhyChooseHead, WhyChooseSub, WhyChooseUSImage, WhyChooseUsText } from './Styles/Home.styles';
import Cards from '../Components/ServiceCards/Cards';
import { HeroBackground, SocialLinks } from '../Components/Hero/Hero.styles';
import { Hero2BackgroundColor } from '../Components/Hero2/Hero2.styles';
import whyimage from "../Assets/Images/Images/clarisse-meyer-jKU2NneZAbI-unsplash.jpg";
import Footer from '../Components/Footer/Footer';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';

function Home() {
    const [ref, inView] = useInView({ threshold: window.innerWidth > 768 ? "0.1" : "0.05" });
    const quoteRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        if (inView) {
            console.log("inview")
            quoteRef.current.style.transform = 'translateX(0)';
            window.innerWidth >= 768 ? imageRef.current.style.transform = 'translateX(0)' : imageRef.current.style.transform = 'none';
        }
    }, [inView]);

    return (
        <>
            <Header />
            <div style={{ overflowX: "hidden" }}>
                <Hero />
                <Hero2 />
                <BannerContainer>
                    <BannerQuote>"A law is valuable not because it is law, but because there is right in it."</BannerQuote>
                </BannerContainer>
                <Cards />
                <div ref={ref}>
                    <HeroBackground style={{ height: window.innerWidth <= 768 && "100%" }} >
                        <WhyChooseUsText ref={quoteRef} style={{ transform: "translateX(-100%)", transition: "transform 1s ease" }}>
                            <WhyChooseHead>WHY CHOOSE US?</WhyChooseHead>
                            <WhyChooseSub>At Sudharma Legal Associates, we take pride in delivering comprehensive legal services across a broad range of cases. Whether you're dealing with civil litigation, criminal charges, matrimonial disputes, or any other legal matter, our experienced team of professional advocates is ready to provide expert guidance and representation.</WhyChooseSub>
                            <WhyChooseSub>Our unwavering commitment and client-focused approach ensures your rights are protected and your needs are prioritized. Choose Sudharma Legal Associates for legal support in all areas of the law. We handle cases in the Punjab and Haryana High Court, as well as district courts across Punjab and Haryana, to achieve the best possible outcomes.</WhyChooseSub>
                        </WhyChooseUsText>
                        <Hero2BackgroundColor ref={imageRef} style={{ transform: window.innerWidth > 768 && "translateX(100%)", transition: window.innerWidth > 768 && "transform 1s ease" }}>
                            <WhyChooseUSImage src={whyimage} />
                        </Hero2BackgroundColor>
                    </HeroBackground>
                </div>
                <SocialLinks>
                    <a href="https://x.com/sudharmalegal" target="_blank" rel="noopener noreferrer">
                        <Twitter sx={{ fontSize: "40px",color:"black"}} />
                    </a>
                    <a href="https://www.instagram.com/sudharmalegal" target="_blank" rel="noopener noreferrer">
                        <Instagram sx={{ fontSize: "40px",color:"black"}} />
                    </a>
                    <a href="https://www.linkedin.com/company/sudharma-legal-associates/about/" target="_blank" rel="noopener noreferrer">
                        <LinkedIn sx={{ fontSize: "40px",color:"black"}} />
                    </a>
                </SocialLinks>
                <Footer />
            </div>
        </>
    );
}

export default Home;
