import styled from "styled-components";


export const Hero2Container=styled.div`
width:100%;
height:700px;
display:flex;
align-items:center;
justify-content:space-between;
@media (max-width: 768px) {
    width:100%;
    height:100%;
    border:none;
  }
`;

export const Hero2BackgroundColor=styled.div`
width:50%;
height:100%;
background-color:#E8E6E6;
display:flex;
align-items:center;
justify-content:center;
@media (max-width: 768px) {
    display:none;
  }
`;

export const Hero2Image=styled.img`
width:100%;
height:80%;
border:20px solid white;
margin-left:30%;
transform: translateX(-100%); /* Initial position outside the viewport on the left */
    transition: transform 1s ease; 
@media (max-width: 768px) {
    display:none;
  }
`;

export const Hero2QuoteCont=styled.div`
width:50%;
height:100%;
padding-left:10%;
display:flex;
align-items:center;
justify-content:space-between;
transform: translateX(100%); /* Initial position outside the viewport on the left */
    transition: transform 1s ease; 
    @media (max-width: 768px) {
        width:100%;
    flex-direction:column;
    padding:20px;
    background-color:#E8E6E6;
    transform:none;
  }
`;

export const Hero2Quote=styled.div`
width:60%;
height:100%;
display:flex;
flex-direction:column;
align-items:start;
justify-content:center;
@media (max-width: 768px) {
    width:100%;
    height:100%;
  }
`;
export const Hero2QuoteHead=styled.span`
font-family:"DIN Neuzeit Grotesk";
font-weight:800;
font-size:50px;
@media (max-width: 768px) {
    font-size:30px;
  }
`;

export const Hero2QuoteSub=styled.span`
font-family:"Playfair Display";
font-weight:500;
font-size:15px;
text-align:justify;
@media (max-width: 768px) {
    font-size:12px;
  }
`;