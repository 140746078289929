import styled from "styled-components";

export const FooterContainer=styled.div`
width:100%;
height:100%;
display:flex;
align-items:center;
box-shadow:2px 0px 4px rgb(0,0,0,0.5);
justify-content:space-evenly;
gap:2rem;
@media(max-width:768px){
    flex-direction:column;
    height:100%;
}
`;

export const FooterAddress=styled.div`
display:flex;
width:35%;
height:100%;
padding:30px;
background-color:black;
flex-direction:column;
align-items:center;
justify-content:center;
@media(max-width:768px){
    width:100%;
}
`;

export const FooterLogo=styled.img`
width:250px;
height:250px;
`;

export const FooterText=styled.div`
display:flex;
flex-direction:column;
align-items:flex-start;
width:100%;
padding:10px;
justify-content:center;
`;

export const GetInTouch=styled.span`
font-family:"DIN Neuzeit Grotesk";
font-size:25px;
font-weight:800;
color:white;
`;

export const Address=styled.span`
font-family:"Raleway";
font-size:15px;
font-weight:500;
color:white;
display:flex;
${'' /* flex-direction:column; */}
align-items:flex-start;
justify-content:center;
gap:2rem;
@media(max-width:768px){
    flex-direction:column;
}
`;

export const MediaLinks=styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:flex-start;
gap:1rem;
`;


export const ContactFormContainer=styled.div`
width:70%;
height:100%;
display:flex;
align-items:center;
justify-content:space-between;

@media(max-width:768px){
    width:100%;
    flex-direction:column;
    padding:10px;
}
`;

export const ContactDiv=styled.div`
display:flex;
flex-direction:column;
padding:30px;
height:100%;
align-items:center;
justify-content:center;
width:100%;
@media(max-width:768px){
    padding:10px;
}
`;