// import React, { useEffect, useRef } from 'react'
// import { useInView } from 'react-intersection-observer'
// import Header from '../Components/Header/Header'
// import Footer from '../Components/Footer/Footer'
// import { AboutHero, AboutHeroQuote, HeroQuote, ImageDiv, Missiondiv } from './Styles/About.styles'
// import { AboutHead, ServiceDesc, ServiceHeading, ServiceImage, ServiceText, Servimg } from './Styles/Services.styles'
// import { SocialLinks } from '../Components/Hero/Hero.styles'
// import { Facebook, Instagram } from '@mui/icons-material'
// import { LegalServicesData } from '../Data/LegalServices'
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import { BannerContainer } from './Styles/Home.styles'
// import banner from "../Assets/Images/Images/vecteezy_judge-gavel-on-a-wooden-desk-law-concept_4340263.jpg"

// function Services() {
//     const [quoteref, quoteinView] = useInView({ threshold: "0" });
//     const quoteRef = useRef(null);

//     useEffect(() => {
//         if (quoteinView) {
//             quoteRef.current.style.transform = 'translateX(0)';
//         }
//     }, [quoteinView]);
//     return (
//         <div>
//         <Header/>
//             <AboutHero ref={quoteref}>
//                 <AboutHeroQuote ref={quoteRef} style={{ transform: "translateX(-100%)", transition: "transform 1s ease" }}>
//                     <AboutHead>Our Services</AboutHead>
//                     <HeroQuote >
//                         This is your Services Page. It's a great opportunity to provide information about the services you provide. Double click on the text box to start editing your content and make sure to add all the relevant details you want to share with site visitors. Whether you're offering multiple services, courses or programs, you can edit this space to fit your website's needs. Simply double click on this section to open the content manager and modify the content. Explain what each item entails and add photos or videos for even more engagement.
//                     </HeroQuote>
//                 </AboutHeroQuote>
//             </AboutHero>
//             {LegalServicesData.map((service, index) => (<>
//                 <Missiondiv key={index} background={service.Image} style={{ height: "100%", padding: window.innerWidth > 768 && "40px", alignItems: window.innerWidth > 768 && "flex-start" }}>
//                     {index % 2 === 0 && (
//                         <React.Fragment >
//                             <ImageDiv>
//                                 <ServiceImage>
//                                     <Servimg src={service.Image} />
//                                 </ServiceImage>
//                             </ImageDiv>
//                             <ServiceText>
//                                 <ServiceHeading>{service.Heading}</ServiceHeading>
//                                 {service.Sub.map((text, subIndex) => (
//                                     <ServiceDesc key={subIndex}>
//                                         <FiberManualRecordIcon style={{ fontSize: window.innerWidth <= 768 ? "7px" : "10px", marginRight: window.innerWidth > 768 ? "20px" : "5px" }} />
//                                         {text}
//                                     </ServiceDesc>
//                                 ))}
//                             </ServiceText>
//                         </React.Fragment>
//                     )}
//                     {index % 2 === 1 && (
//                         <React.Fragment key={index}>
//                             <ServiceText>
//                                 <ServiceHeading>{service.Heading}</ServiceHeading>
//                                 {service.Sub.map((text, subIndex) => (
//                                     <ServiceDesc key={subIndex}>
//                                         <FiberManualRecordIcon style={{ fontSize: window.innerWidth <= 768 ? "7px" : "10px", marginRight: "20px" }} />
//                                         {text}
//                                     </ServiceDesc>
//                                 ))}
//                             </ServiceText>
//                             <ImageDiv>
//                                 <ServiceImage>
//                                     <Servimg src={service.Image} />
//                                 </ServiceImage>
//                             </ImageDiv>
//                         </React.Fragment>
//                     )}
//                 </Missiondiv>
//                 {index === 3 && window.innerWidth>768 && <BannerContainer style={{backgroundImage:`url(${banner})`,height:"500px",backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}}>
//                 </BannerContainer>}
//                 <br /><br /></>
//             ))}
//             <SocialLinks>
//                 <Facebook sx={{fontSize:"40px"}}/>
//                 <Instagram sx={{fontSize:"40px"}}/>
//             </SocialLinks>
//             <Footer/>
//         </div>
//     )
// }
// export default Services


import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import { AboutHero, AboutHeroQuote, HeroQuote, ImageDiv, Missiondiv } from './Styles/About.styles';
import { AboutHead, ServiceDesc, ServiceHeading, ServiceImage, ServiceText, Servimg } from './Styles/Services.styles';
import { SocialLinks } from '../Components/Hero/Hero.styles';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import { LegalServicesData } from '../Data/LegalServices';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { BannerContainer } from './Styles/Home.styles';
import banner from "../Assets/Images/Images/vecteezy_judge-gavel-on-a-wooden-desk-law-concept_4340263.jpg";

function Services() {
    const [quoteref, quoteinView] = useInView({ threshold: "0" });
    const quoteRef = useRef(null);

    useEffect(() => {
        if (quoteinView) {
            quoteRef.current.style.transform = 'translateX(0)';
        }
    }, [quoteinView]);

    return (
        <>
            <Header />
            <div style={{ overflowX: 'hidden' }}>
                <AboutHero ref={quoteref}>
                    <AboutHeroQuote ref={quoteRef} style={{ transform: "translateX(-100%)", transition: "transform 1s ease" }}>
                        <AboutHead>Our Services</AboutHead>
                        <HeroQuote>
                        At Sudharma Legal Associates, we provide a diverse array of legal services customized to meet the unique needs of our clients. We are dedicated to offering expert guidance and representation across various practice areas. Our experience in Punjab and Haryana High Court as well as in the district courts throughout Punjab and Haryana underscores our expertise and capability in delivering effective legal solutions.
                        </HeroQuote>
                    </AboutHeroQuote>
                </AboutHero>
                {LegalServicesData.map((service, index) => (
                    <MissionDiv key={index} index={index} service={service} />
                ))}
                <SocialLinks>
                    <a href="https://x.com/sudharmalegal" target="_blank" rel="noopener noreferrer">
                        <Twitter sx={{ fontSize: "40px",color:"black"}} />
                    </a>
                    <a href="https://www.instagram.com/sudharmalegal" target="_blank" rel="noopener noreferrer">
                        <Instagram sx={{ fontSize: "40px",color:"black"}} />
                    </a>
                    <a href="https://www.linkedin.com/company/sudharma-legal-associates/about/" target="_blank" rel="noopener noreferrer">
                        <LinkedIn sx={{ fontSize: "40px",color:"black"}} />
                    </a>
                </SocialLinks>
                <Footer />
            </div>
        </>
    )
}

function MissionDiv({ index, service }) {
    const [ref, inView] = useInView({ threshold: 0 });
    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(100px)',
    });

    return (
        <animated.div ref={ref} style={animation}>
            <Missiondiv background={service.Image} style={{ height: "100%", padding: window.innerWidth > 768 && "40px", alignItems: window.innerWidth > 768 && "flex-start" }}>
                {index % 2 === 0 && (
                    <React.Fragment>
                        <ImageDiv>
                            <ServiceImage>
                                <Servimg src={service.Image} />
                            </ServiceImage>
                        </ImageDiv>
                        <ServiceText>
                            <ServiceHeading>{service.Heading}</ServiceHeading>
                            {service.Sub.map((text, subIndex) => (
                                <ServiceDesc key={subIndex}>
                                    <FiberManualRecordIcon style={{ fontSize: window.innerWidth <= 768 ? "7px" : "10px", marginRight: window.innerWidth > 768 ? "20px" : "5px" }} />
                                    {text}
                                </ServiceDesc>
                            ))}
                        </ServiceText>
                    </React.Fragment>
                )}
                {index % 2 === 1 && (
                    <React.Fragment key={index}>
                        <ServiceText>
                            <ServiceHeading>{service.Heading}</ServiceHeading>
                            {service.Sub.map((text, subIndex) => (
                                <ServiceDesc key={subIndex}>
                                    <FiberManualRecordIcon style={{ fontSize: window.innerWidth <= 768 ? "7px" : "10px", marginRight: "20px" }} />
                                    {text}
                                </ServiceDesc>
                            ))}
                        </ServiceText>
                        <ImageDiv>
                            <ServiceImage>
                                <Servimg src={service.Image} />
                            </ServiceImage>
                        </ImageDiv>
                    </React.Fragment>
                )}
            </Missiondiv>
            {index === 3 && window.innerWidth > 768 && <BannerContainer style={{ backgroundImage: `url(${banner})`, height: "500px", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
            </BannerContainer>}
            <br /><br />
        </animated.div>
    );
}

export default Services;
