import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { BookAppointmentButton, HeroBackground, HeroBackgroundColor, HeroBackgroundImage, HeroContent, HeroQuote, HeroQuoteContainer, Quote1, Quote2 } from './Hero.styles';
import HeroBack from "../../Assets/Images/Images/inaki-del-olmo-NIJuEQw0RKg-unsplash.jpg";
import { ArrowForward } from '@mui/icons-material';

function Hero() {
    const quoteRef = useRef(null);
    const imageRef = useRef(null);

    const [quoteInView, quoteEntry] = useInView({ threshold: 0.5 }); // Trigger animation when 50% of the component is visible
    const [imageInView, imageEntry] = useInView({ threshold: 0.5 }); // Trigger animation when 50% of the component is visible

    useEffect(() => {
        if (quoteInView  && window.innerWidth >= 768) {
            // Delay the animation by 500 milliseconds
            setTimeout(() => {
                quoteRef.current.style.transform = 'translateX(0)';
            }, 500);
        }
        if (imageInView && window.innerWidth >= 768) {
            // Delay the animation by 500 milliseconds
            setTimeout(() => {
                imageRef.current.style.transform = 'translateX(0)';
            }, 500);
        }
    }, [quoteInView, imageInView]);
    const handleContactClick = () => {
        setTimeout(() => {
          const footerElement = document.getElementById('footer');
          console.log(footerElement);
          if (footerElement) {
            footerElement.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100); // Delay to ensure the navigation completes before scrolling
      };

    return (
        <div>
            <HeroBackground>
                <HeroBackgroundColor>
                    <HeroBackgroundImage ref={imageRef} className="hero-background-image" background={HeroBack}>
                        <HeroContent>
                            <HeroQuoteContainer ref={quoteRef} className="hero-quote-container"> {/* Apply ref for targeting */}
                                <HeroQuote>
                                    <Quote1>EMPOWERING<br />JUSTICE</Quote1>
                                    <Quote2>ENRICHING<br />LIVES</Quote2>
                                    <BookAppointmentButton onClick={handleContactClick}>Book an Appointment <ArrowForward className='arrow-icon' sx={{fontSize:"22px",transition:"margin-left 0.5s ease"}}/></BookAppointmentButton>
                                </HeroQuote>
                            </HeroQuoteContainer>
                        </HeroContent>
                    </HeroBackgroundImage>
                </HeroBackgroundColor>
            </HeroBackground>
        </div>
    )
}

export default Hero;
