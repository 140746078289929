import React, { useEffect, useRef } from 'react'
import { AboutHero, AboutHeroQuote, FocusCard, FocusCont, FocusDiv, FocusHead, FocusHeading, FocusIcon, FocusSub, HeroQuote, HeroQuoteLogo, ImageDiv, MissionHead, MissionText, Missiondiv, QuoteContainer, QuoteHead, QuoteSub, QuotesDiv, WelcomeQuote, WelcomeText } from './Styles/About.styles'
import logo from "../Assets/Images/Logo/Rectangle.png";
import { SocialLinks } from '../Components/Hero/Hero.styles';
import { Facebook, FormatQuoteRounded, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import { BannerContainer } from './Styles/Home.styles';
import missionBack from "../Assets/Images/Images/ai-generated-8679746_1280.jpg";
import visionBack from "../Assets/Images/Images/ai-generated-8679700_1280.jpg";
import { TestimonyData } from '../Data/TestimonyData';
import { FocusData } from '../Data/Focus';
import Header from '../Components/Header/Header';
import { useInView } from 'react-intersection-observer';
import Footer from '../Components/Footer/Footer';

function WhoAreWe() {
    const [quoteref, quoteinView] = useInView({ threshold: "0" });
    const quoteRef = useRef(null);
    const [ref, inView] = useInView({ threshold: 0.3 });
    const textRef = useRef(null);
    const imageRef = useRef(null);
    const [visionref, visioninView] = useInView({ threshold: 0.3 });
    const visiontextRef = useRef(null);
    const visionimageRef = useRef(null);
    const [cardref, cardinView] = useInView({ threshold: window.innerWidth > 768 ? 0.3 : 0.01 });
    const cardRef = useRef(null);
    const [focusref, focusinView] = useInView({ threshold: window.innerWidth > 768 ? 0.3 : 0.01 });
    const focusRef = useRef(null);

    useEffect(() => {
        if (quoteinView) {
            quoteRef.current.style.transform = 'translateX(0)';
        }
        if (inView) {
            textRef.current.style.transform = 'translateX(0)';
            window.innerWidth >= 768 ? imageRef.current.style.transform = 'translateX(0)' : imageRef.current.style.transform = 'none';
        }
        if (visioninView) {
            visiontextRef.current.style.transform = 'translateX(0)';
            window.innerWidth >= 768 ? visionimageRef.current.style.transform = 'translateX(0)' : visionimageRef.current.style.transform = 'none';
        }
        if (cardinView) {
            cardRef.current.style.transform = 'translateY(0)';
        }
        if (focusinView) {
            focusRef.current.style.transform = 'translateY(0)';
        }
    }, [quoteinView, inView, visioninView, cardinView, focusinView]);
    return (
        <>
            <Header />
            <div style={{ overflow: 'hidden' }}>
                <AboutHero ref={quoteref}>
                    <AboutHeroQuote ref={quoteRef} style={{ transform: "translateX(-100%)", transition: "transform 1s ease" }}>
                        <HeroQuoteLogo src={logo} />
                        <HeroQuote>
                            Welcome to Sudharma Legal Associates, where your legal needs are our top priority. We are committed to offering exceptional legal services, customized to address the unique needs of each client. Our dedicated advocates uphold the principles of justice and provide solutions that are both effective and efficient. 
                            Whether you need legal advice, representation, or simply wish to understand your rights, Sudharma Legal Associates is here to assist. We handle cases in the Punjab and Haryana High Court, as well as in district courts across Punjab and Haryana. Contact us today to schedule a consultation and take the first step toward confidently resolving your legal matters.
                        </HeroQuote>
                    </AboutHeroQuote>
                </AboutHero>
                <BannerContainer>
                    <WelcomeText>Welcome to <br />Sudharma Legal Associates</WelcomeText>
                    <WelcomeQuote>We are thrilled to have you with us.<br /><br />Thank you for choosing Sudharma Legal Associates . We look forward to working with you and positively impacting your legal journey.</WelcomeQuote>
                </BannerContainer>
                <Missiondiv background={missionBack} ref={ref}>
                    <ImageDiv background={missionBack} ref={imageRef} x="-100%" />
                    <MissionText ref={textRef} x="100%">
                        <MissionHead>MISSION</MissionHead>
                        <HeroQuote style={{ fontWeight: "400", fontSize: "18px", color: window.innerWidth < 768 && 'white' }}>
                            At Sudharma Legal Associates , our mission is to foster a lawful society and secure justice for our clients. We are devoted to upholding the principles of justice, advocating for rights, and ensuring fairness for all. Through our ethical and dedicated legal services, we aim to positively impact society and contribute to a more just and equitable world.
                        </HeroQuote>
                    </MissionText>
                </Missiondiv>
                <Missiondiv background={visionBack} ref={visionref}>
                    <MissionText ref={visiontextRef} x="-100%">
                        <MissionHead>VISION</MissionHead>
                        <HeroQuote style={{ fontWeight: "400", fontSize: "18px", color: window.innerWidth <= 768 && 'white' }}>
                            We Sudharma Legal Associates recognized for our unwavering commitment to excellence, integrity, and innovation, fostering long-term client relationships built on trust and delivering superior legal solutions that empower our clients to achieve their goals and navigate the complexities of the legal landscape with confidence.
                        </HeroQuote>
                    </MissionText>
                    <ImageDiv background={visionBack} ref={visionimageRef} x="100%" />
                </Missiondiv>
                <div ref={cardref} style={{display:'flex',flexDirection:"column",alignItems:"center",paddingTop:"40px",justifyContent:"center"}}>
                <FocusHead style={{letterSpacing:"0.6em"}}>WHAT OUR CLIENTS SAY ?</FocusHead><br/>
                    <QuotesDiv ref={cardRef} x="100%">
                        {TestimonyData.map((testimony, index) => (
                            <QuoteContainer key={index}>
                                <FormatQuoteRounded sx={{ fontSize: "40px", transform: "rotate(180deg)" }} />
                                <QuoteHead>{testimony.Heading}</QuoteHead>
                                <QuoteSub>"{testimony.Sub}"</QuoteSub>
                            </QuoteContainer>
                        ))}
                    </QuotesDiv>
                </div>
                <div ref={focusref}>
                    <FocusDiv ref={focusRef} x="100%">
                        <FocusHead>OUR FOCUS</FocusHead>
                        <FocusCont>
                            {FocusData.map((focus, index) => (
                                <FocusCard key={index}>
                                    <FocusIcon src={focus.icon} />
                                    <FocusHeading>{focus.Heading}</FocusHeading>
                                    <FocusSub>{focus.Sub}</FocusSub>
                                </FocusCard>
                            ))}
                        </FocusCont>
                    </FocusDiv>
                </div>
                <SocialLinks>
                    <a href="https://x.com/sudharmalegal" target="_blank" rel="noopener noreferrer">
                        <Twitter sx={{ fontSize: "40px",color:"black"}} />
                    </a>
                    <a href="https://www.instagram.com/sudharmalegal" target="_blank" rel="noopener noreferrer">
                        <Instagram sx={{ fontSize: "40px",color:"black"}} />
                    </a>
                    <a href="https://www.linkedin.com/company/sudharma-legal-associates/about/" target="_blank" rel="noopener noreferrer">
                        <LinkedIn sx={{ fontSize: "40px",color:"black"}} />
                    </a>
                </SocialLinks>
                <Footer />
            </div>
        </>
    )
}

export default WhoAreWe