import React, { useEffect, useRef } from 'react';
import { Hero2BackgroundColor, Hero2Container, Hero2Image, Hero2Quote, Hero2QuoteCont, Hero2QuoteHead, Hero2QuoteSub } from './Hero2.styles';
import hero2 from "../../Assets/Images/Images/pexels-sora-shimazaki-5668481.jpg";
import { useInView } from 'react-intersection-observer';

function Hero2() {
    const [ref, inView] = useInView({threshold:0.3});
    const quoteRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        if (inView) {
            quoteRef.current.style.transform = 'translateX(0)';
            window.innerWidth>=768? imageRef.current.style.transform = 'translateX(0)':imageRef.current.style.transform = 'none';
        }
    }, [inView]);

    return (
        <div ref={ref}>
            <Hero2Container>
                <Hero2BackgroundColor>
                    <Hero2Image ref={imageRef} src={hero2} />
                </Hero2BackgroundColor>
                <Hero2QuoteCont ref={quoteRef} background={hero2}>
                    <Hero2Quote>
                        <Hero2QuoteHead>​SUDHARMA <br />LEGAL ASSOCIATES</Hero2QuoteHead>
                        <Hero2QuoteSub>At Sudharma Legal Associates, we take pride in offering a full range of legal services across a diverse array of cases. Whether you're dealing with civil litigation, criminal charges, matrimonial disputes, or any other legal issue, our seasoned team of professional advocates is here to provide expert advice and representation.</Hero2QuoteSub><br />
                        <Hero2QuoteSub>Our dedication to justice and our client-focused approach. At Sudharma Legal Associates, we work tirelessly to secure the best possible outcomes for you. We specialize in handling cases in the Punjab and Haryana High Court, as well as in the district courts of Punjab and Haryana, ensuring reliable and committed legal support in all areas of law.</Hero2QuoteSub>
                    </Hero2Quote>
                </Hero2QuoteCont>
            </Hero2Container>
        </div>
    );
}

export default Hero2;
