import styled from "styled-components";

export const BannerContainer=styled.div`
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:space-between;
background-color:#DCD0BF;
@media(max-width:768px){
    flex-direction:column;
    transform: none;
}
`;

export const BannerQuote=styled.span`
width:100%;
font-family:"Gloock";
font-size:50px;
font-weight:800;
text-align:center;
padding:50px;
@media(max-width:768px){
    font-size:30px;
}
`;

export const WhyChooseUSImage=styled.img`
width:80%;
height:90%;
border-radius:50% 50% 0px 0px;
padding:30px;
`;

export const WhyChooseUsText=styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:flex-start;
gap:2rem;
width:50%;
padding:50px;
@media(max-width:768px){
    width:100%;
    padding:30px;
}
`;

export const WhyChooseHead=styled.span`
font-family:"Poppins";
font-weight:800;
padding:40px;
width:100%;
font-size:40px;
`;

export const WhyChooseSub=styled.span`
font-family:"Cormorant Garamond";
font-weight:500;
font-size:18px;
width:70%;
text-align:justify;
padding:0px 60px;
@media(max-width:768px){
    width:100%;
    padding:0px 30px;
}
`;