import React, { useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { StyledNavbar, Logo, NavLink, Spacer, StyledToggle } from './Header.styles';
import { Link, useNavigate} from 'react-router-dom';
import logo from "../../Assets/Images/Logo/Rectangle.png";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate=useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleContactClick = () => {
        setTimeout(() => {
            const footerElement = document.getElementById('Contact');
            if (footerElement) {
                window.scrollTo(0, document.body.scrollHeight)
            }
    }, 100); // Delay to ensure the navigation completes before scrolling
};

  return (
    <StyledNavbar collapseOnSelect expand="lg">
      <Container>
        <Link to="/">
          <Logo src={logo} alt="Sudharma Legal Logo" aria-label='Sudharma Legal Logo' />
        </Link>
        <StyledToggle
          aria-controls="responsive-navbar-nav"
          onClick={toggleMenu}
          isMenuOpen={isMenuOpen}
        >
          <span></span>
          <span></span>
          <span></span>
        </StyledToggle>
        <StyledNavbar.Collapse id="responsive-navbar-nav" className={isMenuOpen ? 'show' : ''}>
          <Spacer />
          <Nav>
            <NavLink to="/" onClick={toggleMenu} isMenuOpen={isMenuOpen}>
              HOME
            </NavLink>
            <NavLink to="/about" onClick={toggleMenu} isMenuOpen={isMenuOpen}>
              WHY US?
            </NavLink>
            <NavLink to="/services" onClick={toggleMenu} isMenuOpen={isMenuOpen}>
              SERVICES
            </NavLink>
            <NavLink as="div" onClick={handleContactClick} isMenuOpen={isMenuOpen}>
              CONTACT US
            </NavLink>
          </Nav>
        </StyledNavbar.Collapse>
      </Container>
    </StyledNavbar>
  );
}

export default Header;
