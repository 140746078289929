export const TestimonyData=[
    {
        "Heading":"Dedicated and Professional",
        "Sub":"Sudharma Legal Associates has been exceptional in handling our case. Their expertise in the Punjab and Haryana High Court, especially in Chandigarh, is unmatched. They are professional, responsive, and truly the best legal team we could have chosen."
    },
    {
        "Heading":"Highly Skilled Advocate",
        "Sub":"Sudharma Legal Associates Lawyers exceeded our expectations. Their exceptional handling of our case in the High Court, Chandigarh, showcases their dedication. They are truly the best in legal services."
    },
    {
        "Heading":"Compassionate and Understanding",
        "Sub":"We faced a challenging legal issue in district court, and Sudharma Legal Associates provided outstanding support. Their deep understanding of the legal landscape helped us achieve a successful resolution."
    }

];