import styled from "styled-components";

export const AboutHead=styled.span`
width:100%;
font-family:"DIN Neuzeit Grotesk";
font-weight:800;
font-size:60px;
padding:20px;
`;

export const ServiceImage=styled.div`
display:flex;
align-items:center;
width:500px;
height:50%;
background-color:#DCD0BF;
justify-content:Center;
padding:30px;
`;

export const Servimg=styled.img`
width:400px;
height:300px;
object-fit: cover; /* Cover will make sure the image covers the entire container */
  object-position: bottom; /* Start the image from the bottom */
  overflow: hidden;
  position:relative;
top:-80%;
`;


export const ServiceText=styled.div`
width:50%;
display:flex;
flex-direction:column;
padding:0px 30px;
align-items:center;
justify-content:flex-start;
height:100%;
gap:2rem;
transform:translateX(${(props)=>props.x});
transition:transform 1s ease;
@media(max-width:768px){
    width:100%;
    background-color:rgb(0,0,0,0.8);
    align-items:center;
    justify-content:center;
    padding:30px;
}
`;


export const ServiceHeading = styled.span`
    width: 100%;
    font-family: "Cormorant Garamond";
    font-size: 40px;
    font-weight: 800;
    display: inline-block; /* Ensures padding applies correctly */
    padding-bottom: 5px; /* Adjust spacing as needed */
    border-bottom: 2px solid black; /* Change color and style as needed */
    
    @media (max-width: 768px) {
        text-align: center;
        font-size: 40px;
        color: white;
        padding: 10px;
        border-bottom: 2px solid white;
    }
`;

export const ServiceDesc = styled.span`
  width: 90%;
  font-family: "Cormorant Garamond";
  font-size: 20px;
  font-weight: 800;
  display: flex;
  text-align:justify;
  align-items: center;
  @media(max-width:768px){
    text-align:center;
    width:90%;
    font-size:20px;
    color:white;
    text-align:justify;
    padidng:30px;
    font-weight: 100;
}
`;