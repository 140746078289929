// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home';
import WhoAreWe from './Pages/WhoAreWe';
import Services from './Pages/Services';
import ModalComponent from './Pages/ModalComponent';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [showModal, setShowModal] = useState(true);
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(true);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <ModalComponent 
          show={showModal} 
          handleAccept={handleAccept} 
          handleClose={handleClose} 
        />
        {accepted && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<WhoAreWe />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
