import styled, { keyframes } from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';

export const StyledNavbar = styled(Navbar)`
  background-color: white;
  padding: 30px 80px; /* Add padding to the sides */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  position:sticky;
  top:0;
  z-index:999;
  width:100%;
  @media (max-width: 768px) {
    padding: 8px; /* Adjust padding for smaller screens */
  }
`;

export const Logo = styled.img`
  width: auto;
  height: 70px; /* Set your desired height */
  object-fit: contain; /* This will fit the image into the height while maintaining its aspect ratio */
  cursor: pointer;
`;

const slideIn = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const slideOut = keyframes`
  from {
    width: 100%; /* Start from full width */
    margin-left: 0; /* Start from no left margin */
  }
  to {
    width: 0; /* End at no width */
    margin-left: 100%; /* Move the ending point to the right by the full width */
  }
`;


export const NavLink = styled(Link)`
  color: black;
  font-size: 18px;
  font-family: "Raleway";
  text-decoration: none;
  font-weight: 500;
  padding: 10px 30px;
  transition:font-weight 0.2s ease;
  cursor:pointer;
  position: relative; /* Add this line to ensure the pseudo-element is positioned relative to the link */
  &:hover{
    font-weight:700;
  }
  &.active:hover{
    font-weight:800;
  }

  ${'' /* &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: black;
    transition: width 0.3s ease;
    transform-origin: top left;
  }

  &:hover:after {
    width: 100%;
    animation: ${slideIn} 0.3s forwards;
  }
  &:not(:hover):after {
    width: 0;
  }

  &.active:after {
    transition: none; /* Remove the transition for the active state to avoid animation on page load */
  } */}
  &.active{
    font-weight:800;
  }

  @media (max-width: 768px) {
    padding: 10px 5px; /* Adjust padding for smaller screens */
    font-size: 15px;
  }
`;

export const Spacer = styled.div`
  flex-grow: 1; /* Pushes the logo and links to the ends */
`;

export const StyledToggle = styled(Navbar.Toggle)`
  border: none;
  background: transparent;
  padding: 25px;
  outline: none;
  &:focus {
    box-shadow: none !important;
  }

  span {
    display: block;
    width: 30px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: black;
  }

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
    span:nth-child(1) {
      transform: rotate(50deg) translate(6px, 5px);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-50deg) translate(6px, -5px);
    }
  `}
`;
