import styled from "styled-components";

export const AboutHero = styled.div`
width:100%;
display:flex;
height:600px;
align-items:center;
justify-content:space-between;
@media(max-width:768px){
    flex-direction:column;
    height:100%;
}
`;

export const AboutHeroQuote = styled.div`
width:100%;
padding:20%;
display:flex;
flex-direction:column;
align-items:flex-start;
justify-content:center;
@media(max-width:768px){
    padding:30px;
    align-items:center;
}
`;

export const HeroQuoteLogo = styled.img`
width:30%;
padding:20px;
transition:box-shadow 0.3s ease;
&:hover{
    box-shadow:0px 4px 20px rgba(0,0,0,0.5);
}
@media(max-width:768px){
    width:80%;
    padding:0px;
}
`;

export const HeroQuote = styled.span`
width:100%;
text-align:justify;
font-family:"Raleway";
font-weight:500;
font-size:20px;
padding:20px;
@media(max-width:768px){
    font-size:15px;
}
`;

export const WelcomeText = styled.span`
  width: 50%;
  padding: 5%;
  text-align: left;
  display: flex; /* To enable align-items and justify-content */
  align-items: center;
  justify-content: center;
  font-family: "DIN Neuzeit Grotesk";
  font-weight: 800;
  font-size: 60px;
  position: relative; /* Needed for the pseudo-element positioning */
  box-sizing: border-box; /* Include padding in element's total size */
  
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 20%;
    bottom: 0;
    width: 3px;
    height: 60%;
    background-color: black;
  }
  
  @media (max-width: 768px) {
    padding: 20px;
    width: 100%;
    text-align: center;
    
    &::before {
      display: none; /* Hide the right border */
    }
    
    &::after {
      content: "";
      position: absolute;
      left: 10%;
      right: 10%;
      bottom: 0;
      height: 3px;
      width: 80%;
      background-color: black;
    }
  }
`;



export const WelcomeQuote = styled.span`
width:50%;
text-align:left;
font-family:"Raleway";
font-weight:500;
font-size:18px;
padding:50px;
@media(max-width:768px){
    font-size:18px;
    padding:20px;
    text-align:justify;
    width:100%;
}
`;


export const Missiondiv=styled.div`
width:100%;
height:600px;
display:flex;
align-items:center;
justify-content:space-between;
@media(max-width:768px){
    background-image:url(${(props)=>props.background});
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    height:100%;
}
`;

export const ImageDiv=styled.div`
width:50%;
height:600px;
display:flex;
align-items:center;
justify-content:center;
background-image:url(${(props)=>props.background});
background-size:cover;
background-repeat:no-repeat;
transform:translateX(${(props)=>props.x});
transition:transform 1s ease;
@media(max-width:768px){
    display:none;
    transform:none;
    transition:none;
}
`;

export const MissionText=styled.div`
display:flex;
flex-direction:column;
width:50%;
padding:10%;
align-items:flex-start;
justify-content:space-evenly;
transform:translateX(${(props)=>props.x});
transition:transform 1s ease;
@media(max-width:768px){
    padding:20px;
    width:100%;
    background-color:rgb(0,0,0,0.5);
    height:100%;
    transform:none;
    transition:none;
}
`;

export const MissionHead=styled.span`
width:100%;
text-align:center;
font-family:"DIN Neuzeit Grotesk";
font-size:40px;
font-weight:800;
@media(max-width:768px){
    color:white;
}
`;


export const QuotesDiv=styled.div`
width:100%;
display:flex;
height:500px;
align-items:center;
justify-content:center;
transform:translateY(${(props)=>props.x});
transition:transform 1s ease;
@media(max-width:768px){
    height:100%;
    flex-direction:column;
}
`;

export const QuoteContainer=styled.div`
display:flex;
flex-direction:column;
width:40%;
padding:60px;
align-items:center;
justify-content:center;
gap:3rem;
height:100%;
border:1px solid black;
@media(max-width:768px){
    width:100%;
    flex-direction:column;
}
`;

export const QuoteHead=styled.span`
font-family:"DIN Neuzeit Grotesk";
font-weight:1000;
width:100%;
text-align:center;
font-size:30px;
`;

export const QuoteSub=styled.span`
font-family:"DIN Neuzeit Grotesk";
font-weight:600;
width:100%;
text-align:justify;
font-size:20px;
`;

export const FocusDiv=styled.div`
width:100%;
height:100%;
display:flex;
padding:30px;
flex-direction:column;
align-items:center;
justify-content:center;
gap:4rem;
transform:translateY(${(props)=>props.x});
transition:transform 1s ease;
`;

export const FocusHead=styled.span`
font-family:"Poppins";
font-weight:800;
letter-spacing:1.2em;
font-size:40px;
width:100%;
display:flex;
align-items:center;
justify-content:center;
@media(max-width:768px){
    font-size:25px;
    letter-spacing:0.8em;
    text-align:center;
}
`;

export const FocusCont=styled.div`
display:flex;
width:90%;
flex-wrap:wrap;
gap:3rem;
align-items:center;
justify-content:center;
`;


export const FocusCard=styled.div`
display:flex;
width:40%;
padding:30px;
flex-direction:column;
align-items:center;
justify-content:space-evenly;
gap:1rem;
background-color:#F6EBE4;
@media(max-width:768px){
    width:100%;
}
`;

export const FocusIcon=styled.img`
width:90px;
height:90px;
`;

export const FocusHeading=styled.span`
width:100%;
text-align:center;
font-family:"Raleway";
font-size:25px;
font-weight:800;
`;

export const FocusSub=styled.span`
width:100%;
text-align:justify;
font-family:"Raleway";
font-size:15px;
font-weight:500;
`;