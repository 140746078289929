import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Card, CardsContainer, CardsDiv, Service, ServiceHeading, ServiceSub, Icon, ServicesButton, ButtonDiv, Icon2, Card2 } from './Cards.styles';
import { ServicesData, Specialities } from '../../Data/ServicesData';
import { ArrowForward } from '@mui/icons-material';
import { BannerContainer } from '../../Pages/Styles/Home.styles';

function Cards() {
    const [ref, inView] = useInView({ threshold: window.innerWidth>768? "0.1":"0.01" });
    const serviceHeadingRef = useRef(null);
    const cardsContainerRef = useRef(null);

    useEffect(() => {
        if (inView) {
            serviceHeadingRef.current.style.transform = 'translateY(0)';
            cardsContainerRef.current.style.transform = 'translateY(0)';
        }
    }, [inView]);

    return (
        <div ref={ref}>
            <div ref={serviceHeadingRef} style={{ transform: "translateY(100%)", transition: "transform 1s ease" }}>
                <ServiceHeading >
                    Our Services
                    <ServiceSub>
                        Comprehensive and Customized Legal Services for Every Legal Challenge
                    </ServiceSub>
                </ServiceHeading>
                <CardsContainer>
                    <CardsDiv>
                        {ServicesData.map((service, index) => (
                            <Card key={index}>
                                <Icon src={service.serviceIcon} alt={service.serviceName} />
                                <Service>{service.serviceName}</Service>
                            </Card>
                        ))}
                        <ButtonDiv><ServicesButton to="/services">OTHER SERVICES<ArrowForward className='arrow' sx={{ transition: "margin-left 0.5s ease" }} /> </ServicesButton></ButtonDiv>
                    </CardsDiv>
                </CardsContainer>
            </div>
            <BannerContainer ref={cardsContainerRef} style={{ transform: window.innerWidth > 768 && "translateY(200%)", transition: window.innerWidth > 768 && "transform 1s ease" , padding: "20px", gap: window.innerWidth <= 768 ? "0.5rem" : "3rem", flexDirection: window.innerWidth <= 768 && 'column' }}>
                {Specialities.map((service, index) => (
                    <Card2 key={index}>
                        <Icon2 src={service.Icon} alt={service.serviceName} />
                        <Service>{service.Text}</Service>
                    </Card2>
                ))}
            </BannerContainer>
        </div>
    );
}

export default Cards;
