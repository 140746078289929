import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeroBackground = styled.div`
width:100%;
height:700px;
display:flex;
justify-content:flex-end;
`;

export const HeroBackgroundColor = styled.div`
width:50%;
height:100%;
display:flex;
align-items:center;
justify-content:space-between;
background-color:#DCD0BF;
@media (max-width: 768px) {
    background-color:white;
    flex-direction:column;
    width:100%;
    height:100%;
  }
`;

export const HeroBackgroundImage = styled.div`
width:120%;
height:90%;
margin-left:-90%;
display:flex;
align-items:end;
padding:15px;
background-image:url(${(props) => props.background});
background-size:cover;
transform: translateX(-100%); /* Initial position outside the viewport on the left */
    transition: transform 1s ease; 
border:20px solid white;
@media (max-width: 768px) {
    width:100%;
    height:100%;
    margin-left:0%;
    border:none;
    padding:50px;
    align-items:center;
transform: none; /* Initial position outside the viewport on the right */
    transition: none; 
  }
`;
export const HeroContent = styled.div`
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:space-between;
@media (max-width: 768px) {
    justify-content:center;
    flex-direction:column;
    background-color:rgb(0,0,0,0.3);
  }
`;

export const HeroQuoteContainer = styled.div`
width:80%;
position:absolute;
left:40%;
background-color:white;
transform: translateX(200%); /* Initial position outside the viewport on the right */
    transition: transform 1s ease; 
padding:15px;
@media (max-width: 768px) {
    left:auto;
    width:550px;
    position:relative;
transform: none; /* Initial position outside the viewport on the right */
    transition: none; 
  }
  @media (max-width: 468px) {
    left:auto;
    width:400px;
    position:relative;
  }
`;

export const HeroQuote = styled.div`
width:100%;
height:100%;
background-color: #E8E6E6;
display:flex;
flex-direction:column;
justify-content:center;
padding:30px;
align-items:start;
`;

export const Quote1 = styled.span`
font-family:"DIN Neuzeit Grotesk";
font-weight:800;
font-size:65px;
color:#6B6B6B;
`;

export const Quote2 = styled.span`
font-family:"DIN Neuzeit Grotesk";
font-weight:800;
font-size:65px;
color:black;
`;

export const BookAppointmentButton = styled(Link)`
font-family:"Raleway";
font-weight:500;
padding-top:20px;
display:flex;
align-items:Center;
gap:1rem;
font-size:18px;
color:black;
text-decoration:none;
&:hover{
    > .arrow-icon{
        margin-left:25px;
    }
}
`;


export const SocialLinks = styled.div`
padding:5px;
height:100%;
display:flex;
flex-direction:column;
position:fixed;
top:0;
right:0;
align-items:end;
justify-content:center;
gap:1rem;
@media (max-width: 768px) {
    flex-direction:row;
    display:none;
  }
`;