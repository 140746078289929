export const ServicesData=[
    {
        "serviceName":"CRIMINAL CASES",
        "serviceIcon":require("../Assets/Images/trial.png")
    },
    {
        "serviceName":"CONSUMER COMPLAINTS",
        "serviceIcon":require("../Assets/Images/complaint.png")
    },
    {
        "serviceName":"CIVIL SUITS",
        "serviceIcon":require("../Assets/Images/home.png")
    },
    {
        "serviceName":"MATRIMONIAL DISPUTES",
        "serviceIcon":require("../Assets/Images/family.png")
    },
    {
        "serviceName":"COURT MARRIAGES",
        "serviceIcon":require("../Assets/Images/balance.png")
    },
    {
        "serviceName":"CHEQUE BOUNCE MATTERS",
        "serviceIcon":require("../Assets/Images/bank.png")
    },
    {
        "serviceName":"LABOUR LAW",
        "serviceIcon":require("../Assets/Images/working-at-home.png")
    },
    {
        "serviceName":"DEED AND AGREEMENT",
        "serviceIcon":require("../Assets/Images/agreement.png")
    }
];


export const Specialities=[
    {
        "Text":"DEDICATION TO JUSTICE",
        "Icon":require("../Assets/Images/happiness.png")
    },
    {
        "Text":"CLIENT CENTERED APPROACH",
        "Icon":require("../Assets/Images/star.png")
    },
    {
        "Text":"COMPREHENSIVE LEGAL APPROACH",
        "Icon":require("../Assets/Images/globe.png")
    },
    {
        "Text":"EXCEPTIONAL PROFESSIONALISM",
        "Icon":require("../Assets/Images/thunder.png")
    }
];