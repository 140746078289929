// ModalComponent.js
import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";

function ModalComponent({ show, handleAccept, handleClose }) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Define inline styles for the modal
  const modalStyle = {
    maxWidth: "800px", // Default width for smaller screens
    margin: "auto",
    fontFamily: "Raleway",
    fontWeight: 800,
  };

  // Check screen size and adjust the width for larger screens
  if (window.innerWidth > 768) {
    modalStyle.maxWidth = "90%"; // Adjust width for larger screens
  }

  const titleStyle = {
    fontFamily: "Raleway",
    fontWeight: 800,
  };

  const bodyStyle = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  };
  const buttonStyle = {
    backgroundColor: '#DCD0BF', // Set button color to #DCD0BF
    borderColor: '#DCD0BF', // Set border color to #DCD0BF
    color: 'black', // Set text color to black
    width:"50%",
    fontFamily:"Raleway",
    fontWeight:"800"
  };
  const textStyle = {
    fontFamily: "Raleway",
    fontSize: "15px",
    textAlign: "justify",
    width:"100%",
    fontWeight: "400",
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={titleStyle}>DISCLAIMER</Modal.Title>
      </Modal.Header>
      <Modal.Body style={bodyStyle}>
        <h6 style={textStyle}>
          The rules of the Bar Council of India prohibit law firms from
          soliciting work or advertising in any manner. By clicking, “I Agree”
          below, please confirm that you have:
        </h6>
        <br />
        <div style={bodyStyle}>
          <span style={textStyle}>
            1. Not in any manner been advertised, solicited, invited or induced
            to provide any work or mandate to PLA or its members
          </span>
          <span style={textStyle}>
            2. The information is made available/provided to the user only on
            his/her specific request and any information obtained or material
            downloaded from this website is completely at the user's volition
            and any transmission, receipt or use of this site is not intended
            to, and will not, create any lawyer-client relationship.
          </span>
          <span style={textStyle}>
            3. None of the information contained on the website is in the nature
            of a legal opinion or otherwise amounts to any legal advice.
          </span>
          <span style={textStyle}>
            4. We are not liable for any consequence of any action taken by you
            relying on the material/information provided on this website
          </span>
        </div>
        <br />
        <h6 style={textStyle}>
          Sudharma Legal Associates is not liable for any consequence of any
          action taken by the user relying on material/information provided
          under this website. In cases where the user has any legal issues,
          he/she in all cases must seek independent legal advice.
        </h6>
        <br />
        <Form>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="I accept the terms and conditions"
              onChange={handleCheckboxChange}
            />
          </Form.Group>
        </Form>
        {!isChecked && (
          <Alert variant="danger" className="mt-3">
            Please accept the terms and conditions to proceed.
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer style={{justifyContent:"center"}}>
        <Button style={buttonStyle} variant="primary" onClick={handleAccept} disabled={!isChecked}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalComponent;
