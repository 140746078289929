import { Link } from "react-router-dom";
import styled from "styled-components";

export const ServiceHeading=styled.div`
width:100%;
height:100%;
padding:40px;
display:flex;
flex-direction:column;
font-size:50px;
font-family:"DIN Neuzeit Grotesk";
font-weight:800;
align-items:center;
text-align:justify;
justify-content:center;
background-color:#E8E6E6;
`;

export const ServiceSub=styled.span`
font-size:18px;
font-family:"Raleway";
font-weight:500;
text-align:center;
`;


export const CardsContainer=styled.div`
width:100%;
height:100%;
padding:30px;
display:flex;
align-items:center;
justify-content:flex-end;
gap:1rem;
background-color:#E8E6E7;
@media(max-width:768px){
    flex-direction:column;
    justify-content:center;
}
`;

export const Card=styled.div`
display:flex;
width:400px;
padding:20px;
height:250px;
flex-direction:column;
align-items:center;
justify-content:center;
gap:2rem;
border:1px solid #DCD0BF;
transition:box-shadow 0.3s ease;
&:hover{
    box-shadow:0px 4px 8px rgb(0,0,0,0.5);
}
`;

export const Icon=styled.img`
width:100px;
height:100px;
object-fit:contain;
`;

export const Service=styled.span`
text-align:justify;
font-size:20px;
font-weight:600;
font-family:"DIN Neuzeit Grotesk";
`;


export const CardsDiv=styled.div`
width:100%;
padding:30px;
display:flex;
flex-wrap:wrap;
gap:2rem;
align-items:center;
justify-content:space-evenly;
`;

export const ButtonDiv=styled.div`
display:flex;
width:400px;
height:250px;
flex-direction:column;
align-items:center;
justify-content:center;
gap:2rem;
`;

export const ServicesButton=styled(Link)`
width:80%;
height:60px;
border-radius:25px;
border:4px solid #dbb895;
background-color:#dcd0bf;
color:black;
font-family:"Raleway";
font-size:12px;
font-weight:700;
display:flex;
align-items:center;
justify-content:center;
gap:1rem;
text-decoration:none;
&:hover{
    > .arrow{
        margin-left:15px;
    }
}
`;


export const Icon2=styled.div`
width:80px;
height:80px;
border-radius:50%;
background-image:url(${(props)=>props.src});
background-color:white;
background-size: 40px;
    background-repeat: no-repeat;
    background-position: center;
`;

export const Card2=styled.div`
display:flex;
width:300px;
padding:20px;
height:250px;
flex-direction:column;
align-items:center;
justify-content:center;
gap:2rem;
`;